//这个函数是local 界面 获取数字数组中特定数字（正确的pageId）的所有索引
export function getAllIndexes(arr, num) {
  return arr.reduce(function(acc, el, index) {
    if (el === num) {
      acc.push(index);
    }
    return acc;
  }, []);
}
//从一个数字数组中找出出现最多次数的数字(正确的PageId)
export function findMostFrequentNumber(arr) {
  let count = {};
  let maxCount = 0;
  let mostFrequentNumber;

  // 统计数组中每个数字的出现次数
  for (let num of arr) {
    count[num] = (count[num] || 0) + 1;
    if (count[num] > maxCount) {
      maxCount = count[num];
      mostFrequentNumber = num;
    }
  }

  return mostFrequentNumber;
}
// 用一个索引数组从一个数字数组中提取值，形成一个新数组
export function extractValuesByIndexes(arr, indexes) {
  return indexes.map(function(index) {
    return arr[index];
  });
}
//拿去整包里的 []
export function extractArrayFromString(str) {
  const regex = /\[([\s\S]*?)\]/g; // 匹配以 [ 开头、] 结尾的内容
  const matches = str.match(regex);
  if (matches) {
    return matches.map((item) => JSON.parse(item));
  }
}
//判断字符串中是否有对象
export function hasObjectInString(str) {
  const regex = /{.*?}/s; // 匹配最短的以 { 开头、} 结尾的内容

  return regex.test(str);
}
// 拿内包
export function extractObjectsFromString(str) {
  //删除第一个 { 和最后一个 } 字符
  str = str.substring(1, str.length - 1);
  // console.log('str', str);
  const regex = /{.*?}/g; // 全局匹配最短的以 { 开头、} 结尾的内容
  // const regex = '\\{"([a-zA-Z_]+)":(.+)}'; // 全局匹配最短的以 { 开头、} 结尾的内容
  const matches = str.match(regex);
  console.log('matches', matches);
  console.log('str', str);
  //拿到里面所有内包对象
  const objects = matches.map((item) => {
    //清除str 里面的 这些内包
    str = str.replace(item, '');
    console.log('每一轮的:', str, '以及:', item);
    if (isInsideJSON(item)) {
      //内包里面还有内保{"data":"7CDFA1E9ECF4@[0,3002,{"data":"7CDFA1E9ECF4@[0,304。。。]"}
    }
    return item;
  });
  str = '{' + str + '}';
  console.log('str2', str, 'objects', objects);

  //将剩下的一个对象 和 内包拼接成字符串返回
  return str + objects;
}
export function isBatchJSON(str1) {
  const str = str1.replace(/[\r\n]+/g, '');
  //首先判断这个字符串JSON 或者 多个Jsonl
  if (
    isStringStartAndEndsWith(str) &&
    countCharOccurrences(str, '{') - 1 == countCharOccurrences(str, '}{')
  ) {
    return true;
  } else {
    return false;
  }
}
export function isInsideJSON(str1) {
  //首先判断这个字符串JSON 或者 多个Json
  const str = str1.replace(/[\r\n]+/g, '');

  if (
    isStringStartAndEndsWith(str) &&
    countCharOccurrences(str, '{') - 1 !== countCharOccurrences(str, '}{')
  ) {
    return true;
  } else {
    return false;
  }
}
// 一个字符串出现一个字符的次数
function countCharOccurrences(str, char) {
  const regex = new RegExp(char, 'g');
  const matches = str.match(regex);
  if (matches) {
    return matches.length;
  }
  return 0;
}
// 判断一个字符串 是不是从{开始  }结束
function isStringStartAndEndsWith(str) {
  return str.startsWith('{') && str.endsWith('}');
}
