<template>
  <div style="display: flex; justify-content: center">
    <div class="pc">
      <div class="pcUp">
        <el-button
          type="warning"
          plain
          style="margin-left: 10px"
          @click="sendMsg()"
        >
          发送
        </el-button>
        <div>
          <div style="display: flex; justify-content: center">
            <el-tag>*请选择波特率</el-tag>
          </div>
          <div style="display: flex; justify-content: center">
            <el-select
              style="margin-top: 10px"
              v-model="baudRate"
              placeholder="请选择波特率"
            >
              <el-option
                v-for="item in baudRates"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div style="display: flex; justify-content: center">
            <el-button @click="aaa()">*点击看平滑</el-button>
          </div> -->
        </div>
        <el-button
          type="success"
          plain
          style="margin-left: 10px"
          @click="serial()"
        >
          1.启动串口调试
        </el-button>
      </div>
      <div class="buttons">
        <div class="buttonsOne">
          <span>实时笔画数量</span>
          {{ loaclDotsLength }}
        </div>
        <div class="buttonsTwo">
          <span>实时丢点数量</span>
          {{ loaclLoseDotsLength }}
        </div>
      </div>
      <div class="pcDown">
        <!-- <el-button
          type="warning"
          @click="getLocalDots(1)"
          style="margin-left: 10px"
        >
          查看本地实时(点)数据
        </el-button>
        <el-button type="warning" @click="getLocalDots(2)">
          查看本地实时(线)数据
        </el-button> -->
        <el-button type="primary" @click="onClickCircle()">
          点击本地成点
        </el-button>
        <el-button type="primary" @click="onClickLine()">
          点击本地成线
        </el-button>
        <el-button type="primary" @click="onClick()">
          点击送点
        </el-button>
        <el-button type="primary" @click="onClickClose()"> 关闭串口 </el-button>
        <el-button type="primary" @click="onClickClear()"> 清除画布 </el-button>
      </div>
      <div>
        <el-input
          style="margin-bottom: 10px;"
          type="textarea"
          :rows="6"
          placeholder="请输入要呈现点的字符串"
          v-model="textarea"
        >
        </el-input>
        <el-button type="primary" @click="transform()">
          确定
        </el-button>
      </div>
    </div>
    <div style="display: flex; align-items: flex-start; margin-left: 40px">
      <canvas
        id="myCanvas"
        :width="enlargeWidth"
        :height="enlargeHeight"
      ></canvas>
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric';
import { Message } from 'element-ui';
import {
  getAllIndexes,
  findMostFrequentNumber,
  extractValuesByIndexes,
  isBatchJSON,
  isInsideJSON,
  extractArrayFromString,
  extractObjectsFromString,
  hasObjectInString,
} from '../utils/locals.js';
export default {
  data() {
    return {
      beforeString: '', //前半部分字符串，可能传过来一个字节只有前半部分，在这里存着，  后半部分不用传 因为直接拼接放数组了
      baudRate: 115200, //256个  17个/264个  73个 212个  20/112个
      baudRates: [115200, 57600, 38400, 19200, 9600, 10], //波特率  波特率越高，传输速度越快，
      //但也会增加传输错误的可能性，波特率越高，传输距离越短，因为高速传输会导致信号衰减，如果硬件设备不支持高速传输，则无法使用高波特率。
      recodeStartCouterState: false, //记录最开始的点的couter true： 进入dotype==1 就记，
      //false:就表示已经记过了不用记  ,为了查看整体丢笔数
      recodeStartCouter: 0, //记录的第一个conter的值
      keepReading: false, // 用户单击按钮关闭串口的属性。
      loaclLoseDotsLength: 0, //实时丢点数量
      loaclDotsLength: 0, //实时点数据数量
      xData: [], //组一笔下来X的点集合
      yData: [], //组一笔下来y的点集合
      enlargeWidth: 210 * 2.5,
      enlargeHeight: 297 * 2.5,
      cxt: '',
      ca: null,
      writer: null,
      getData: [],
      detelPageIdArr: [],
      timeOut: null,
      port: null,
      reader: null,
      testArr: [],
      testArrNum: -1,
      textarea: '',
    };
  },
  mounted() {
    // this.transform();
    var canvas = new fabric.Canvas('myCanvas');
    this.ca = canvas;
    this.readyXY();
    //canvas初始化 start
    canvas.on('mouse:down', function() {
      this.panning = true;
      canvas.selection = false;
    });
    //鼠标抬起事件
    canvas.on('mouse:up', function() {
      this.panning = false;
      canvas.selection = true;
    });
    // 移动画布事件
    canvas.on('mouse:move', function(e) {
      if (this.panning && e && e.e) {
        var delta = new fabric.Point(e.e.movementX, e.e.movementY);
        canvas.relativePan(delta);
      }
    });
    // 鼠标滚动画布放大缩小
    canvas.on('mouse:wheel', function() {
      var zoom = (event.deltaY > 0 ? -0.1 : 0.1) + canvas.getZoom();
      zoom = Math.max(1, zoom); //最小为原来的1/10
      zoom = Math.min(8, zoom); //最大是原来的8倍
      var zoomPoint = new fabric.Point(event.pageX, event.pageY);
      canvas.zoomToPoint(zoomPoint, zoom);
    });
  },
  methods: {
    onClick() {
      //点击送点
      if (this.testArr.length == 0) {
        Message({
          message: '请填写下方文本域',
          type: 'warning',
        });
        return;
      }

      if (this.testArr.length - 1 == this.testArrNum) {
        Message({
          message: '这是最后一笔',
          type: 'warning',
        });
        this.ca.clear();
        this.testArrNum = -1;
        return;
      } else {
        this.testArrNum++;
      }
      if (this.testArr.length - 2 == this.testArrNum) {
        Message({
          message: '这是倒数第二笔了',
          type: 'warning',
        });
      }
      console.log('每点击送的点', this.testArr[this.testArrNum]);
      this.dots_draw(this.testArr[this.testArrNum]);
    },
    async onClickClose() {
      //但是，当使用循环从串行设备连续读取数据时，端口Readable将一直被锁定，直到遇到错误。
      //在这种情况下，调用reader.cancel()将强制reader.read()立即解析为{value: undefined, done: true}，
      //从而允许循环调用reader.releaseLock()。

      // 强制reader.read()立即并随后解析 让  _this.reader.read()={done:true}
      // 在上面的循环例子中调用reader.releaseLock()。
      console.log('this.reader', this.reader);
      if (!this.keepReading) {
        Message({
          message: '已无串口连接',
          type: 'warning',
        });
        return;
      }
      this.keepReading = false;
      this.reader.cancel();
      // await this.readUntilClosed();
    },

    async serial() {
      console.log('已经创建串口实例');

      let _this = this;

      // 浏览器支持serial
      // console.log(1, navigator);
      if ('serial' in navigator) {
        console.log('当前浏览器支持serial');
        this.keepReading = true;
        // 提示用户选择一个串口(requestPort : 获取授权串口)
        if (this.port) {
          this.$message({
            message: '串口已连接,请关闭串口重试',
            type: 'warning',
          });
          return;
        }
        //第一次进入将 记第一个点的couter  ,为了查看整体丢笔数
        this.recodeStartCouterState = true;
        //清除本地点数据以及画布
        this.$store.commit('clearDotsData');
        this.recodeStartCouterState = true;
        this.ca.clear();
        this.loaclDotsLength = 0;
        this.loaclLoseDotsLength = 0;
        this.port = await navigator.serial.requestPort();
        // 获取用户之前授予网站访问权限的所有串口.
        // const ports = await navigator.serial.getPorts();
        //打开串口
        //baudRate：波特率,dataBits：数据位（7 或 8）
        //stopBits：停止位（1 或 2）,parity：校验位"none"、"even"、"odd"
        //bufferSize：读写缓冲区大小（必须小于 16MB）
        //flowControl：流量控制模式（或）。"none"、"hardware"
        //open-----打开串口
        // console.log('port', port);
        //115200 : 256
        let that = this;
        console.log('当前波特率为：', that.baudRate);
        await this.port.open({ baudRate: that.baudRate }); //通信速度
        Message({
          message: 'serial启动成功',
          type: 'success',
        });
        //read—port.readable.getReader()的读取字节数组方法
        this.reader = _this.port.readable.getReader();
        //port.writable.getWriter()的写入方法
        this.writer = _this.port.writable.getWriter();
        try {
          while (_this.port.readable && this.keepReading) {
            const { value, done } = await _this.reader.read();
            if (done) {
              //如果done为真，则串行端口已经关闭，或者没有更多的数据输入
              //releaseLock:Reader和.Writer的释放方法
              console.log('进入done里了吧');
              break;
            }
            let dataString = new TextDecoder().decode(value).trim();
            console.log('每一笔日志', dataString);
            let curDateString = this.beforeString + dataString;
            // console.log('拼接', curDateString);

            //先判断是不是整包（JSON格式）
            if (curDateString.indexOf('ESP-ROM') !== -1) {
              //没接解析出来的不管
              console.log('报错第一笔 不管');
              curDateString = '';
            } else if (isBatchJSON(curDateString)) {
              this.beforeString = '';
              //查看是不是多个JSON数组  处理数据
              let dotData = extractArrayFromString(curDateString);
              this.processingData(dotData);
              console.log('组装完的一笔数据', dotData);
            } else if (isInsideJSON(curDateString)) {
              this.beforeString = '';
              console.log('进内包了');
              console.log('curDateString');
              this.$message({ message: '进内包了', type: 'warning' });
              //判断拼接的数据是不是内包 -- 有就拿出来（一个对象数组）
              let dotData = extractObjectsFromString(curDateString);
              //一个数组数组
              dotData = extractArrayFromString(dotData);
              this.processingData(dotData);
              console.log('组装完的一笔数据', dotData);
            } else {
              // 断的字符串
              // console.log('拼接断包', curDateString);
              this.beforeString = curDateString;
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          console.log('允许稍后关闭串口。');
          this.$message({
            message: '发送错误,请关闭串口重新连接',
            type: 'warning',
          });
        }

        // console.log('最后一步之前都没报错');
        this.reader.releaseLock();
        this.writer.releaseLock();
        await _this.port.close();
        this.port = null;
        this.$message({
          message: '串口已关闭',
          type: 'info',
        });
      } else {
        console.log('不支持');
      }
    },
    //处理已经是二维数组的数据（串口发过来的）
    processingData(dotData) {
      dotData.forEach((strData) => {
        //存入本地  或者放入this.xData,yData  --绘图
        // console.log('是Json 数组  (可能是多个)', strData);
        this.$store.commit('getDotsData', strData);
        if (strData[3] == 0) {
          this.xData = [];
          this.yData = [];
          //通过pageid 的不同区分  飞点 并删除
          this.detelPageIdArr = [];
          // 这个点应该也扔xData，yData（目前点是-1先放着）
        } else if (strData[3] == 1) {
          //拿取这一笔所有pageId 放进数组
          this.detelPageIdArr.push(strData[2]);
          this.xData.push(strData[5]);
          this.yData.push(strData[6]);
        } else if (strData[3] == 2) {
          //绘制
          if (this.xData.length < 2) {
            console.log('这一笔点不够画不了线');
          }
          //绘制之前  把不同的pageId给踢出去
          if (this.detelPageIdArr.length <= 2) {
            // console.log('这一笔才 type==1 的才两个点,无法判断正确pageId');
          } else {
            // --拿到正确的pageId
            let rightPageId = findMostFrequentNumber(this.detelPageIdArr);
            //找到所有正确pageId的索引
            let rightPageIdArr = getAllIndexes(
              this.detelPageIdArr,
              rightPageId
            );
            this.xData = extractValuesByIndexes(this.xData, rightPageIdArr);
            this.yData = extractValuesByIndexes(this.yData, rightPageIdArr);
          }
          //状态等于2的时候本地数量加1
          this.loaclDotsLength++;
          this.dots_drawLine(this.xData, this.yData);
        } else {
          alert('pen_status不对');
        }
      });
    },
    onClickClear() {
      //还原是否记第一个点的couter  ---当成重进serial
      this.recodeStartCouterState = true;
      this.recodeStartCouter = 0;
      //清楚笔画
      this.loaclLoseDotsLength = 0;
      this.loaclDotsLength = 0;
      this.testArrNum = -1;
      //重置放大缩小
      this.ca.setZoom(1);
      this.ca.absolutePan({ x: 0, y: 0 });
      //清除画布
      this.ca.clear();
      //清楚点数据
      this.$store.commit('clearDotsData');
      setTimeout(() => {
        this.readyXY();
      }, 200);
    },

    onClickLine() {
      this.ca.clear();
      setTimeout(() => {
        this.readyXY();
      }, 200);
      this.loaclDotsLength = 0;
      if (this.testArrNum > 0) {
        //这是 从文本框拿的数据数据
        let x = [];
        let y = [];
        let pageIdArr = [];
        this.testArr.map((item) => {
          if (item[3] == 0) {
            x = [];
            y = [];
            pageIdArr = [];
            // x.push(item[5]);
            // y.push(item[6]);
          } else if (item[3] == 1) {
            x.push(item[5]);
            y.push(item[6]);
            //那所有pageId放进数组
            pageIdArr.push(item[2]);
          } else {
            //绘制之前  把不同的pageId给踢出去
            if (pageIdArr.length <= 2) {
              console.log('点不足两个  无法成线');
            } else {
              // --拿到正确的pageId
              let rightPageId = findMostFrequentNumber(pageIdArr);

              //找到所有正确pageId的索引   参数(全部的pageId数组，正确的pageId)
              let rightPageIdArr = getAllIndexes(pageIdArr, rightPageId);

              x = extractValuesByIndexes(x, rightPageIdArr);
              y = extractValuesByIndexes(y, rightPageIdArr);
              console.log(
                '这一笔的所有pageId:',
                pageIdArr,
                '以及正常的pageID:',
                rightPageId,
                '以及筛选过的最后列表x:',
                x,
                '以及筛选过的最后列表y:',
                y
              );
            }
            //抬笔事件
            // x.push(item[5]);
            // y.push(item[6]);
            // console.log(x, y);
            this.dots_drawLine(x, y);
          }
        });
      } else {
        //点击成线
        let x = [];
        let y = [];
        let pageIdArr = [];
        let data = JSON.parse(JSON.stringify(this.$store.state.dotsData));
        if (data.length == 0) {
          this.$message({
            message: '当前没有点数据',
            type: 'warning',
          });
          return;
        }
        //将0，2点去掉算丢笔
        let newData = data.filter((item) => item[3] == 1);
        console.log('newData', newData);
        this.loaclLoseDotsLength =
          newData[newData.length - 1][1] - newData[0][1] + 1 - newData.length;
        data.map((item) => {
          if (item[3] == 0) {
            x = [];
            y = [];
            pageIdArr = [];
            // x.push(item.x[0]);
            // y.push(item.y[0]);
          } else if (item[3] == 1) {
            x.push(item[5]);
            y.push(item[6]);
            //那所有pageId放进数组
            pageIdArr.push(item[2]);
          } else {
            //抬笔事件
            // x.push(item.x[0]);
            // y.push(item.y[0]);
            // console.log(x, y);
            //绘制之前  把不同的pageId给踢出去
            if (pageIdArr.length <= 2) {
              console.log('点不够无法成线');
            } else {
              // --拿到正确的pageId
              let rightPageId = findMostFrequentNumber(pageIdArr);
              //找到所有正确pageId的索引 4,6,8
              let rightPageIdArr = getAllIndexes(pageIdArr, rightPageId);
              x = extractValuesByIndexes(x, rightPageIdArr);
              y = extractValuesByIndexes(y, rightPageIdArr);
              console.log(
                '这一笔的所有pageId:',
                pageIdArr,
                '以及正常的pageID:',
                rightPageId,
                '以及筛选过的最后列表x:',
                x,
                '以及筛选过的最后列表y:',
                y
              );
            }
            this.loaclDotsLength++;
            this.dots_drawLine(x, y);
          }
        });
      }
    },
    onClickCircle() {
      //点击成点
      this.ca.clear();
      setTimeout(() => {
        this.readyXY();
      }, 200);
      this.loaclDotsLength = 0;
      //深拷贝一下
      let data = JSON.parse(JSON.stringify(this.$store.state.dotsData));

      if (data.length == 0) {
        this.$message({
          message: '当前没有点数据',
          type: 'warning',
        });
        return;
      }
      //将0，2点去掉算丢笔
      let newData = data.filter((item) => item[3] == 1);
      console.log('newData', newData);
      //看看丢点数量(最后一个contain值-第一个contain值)
      this.loaclLoseDotsLength =
        newData[newData.length - 1][1] - newData[0][1] + 1 - newData.length;
      data.forEach((item) => {
        this.dots_draw(item);
        if (item[3] == 2) {
          this.loaclDotsLength++;
        }
      });
    },
    aaa() {
      // 创建一个 Canvas 对象

      // 创建线段对象
      let line1 = new fabric.Line([100, 100, 200, 200], { stroke: 'black' });
      let line2 = new fabric.Line([200, 200, 300, 200], { stroke: 'black' });

      // 计算拐点坐标
      let midX = (line1.x2 + line2.x1) / 2;
      let midY = (line1.y2 + line2.y1) / 2;

      // 创建贝塞尔曲线路径
      let curve = new fabric.Path(
        'M ' +
          line1.x2 +
          ' ' +
          line1.y2 +
          ' Q ' +
          midX +
          ' ' +
          midY +
          ' ' +
          line2.x1 +
          ' ' +
          line2.y1,
        {
          stroke: 'red',
          fill: '',
          strokeWidth: 3,
          selectable: false,
        }
      );

      // 添加对象到画布
      this.ca.add(line1, line2, curve);
    },
    dots_draw(data) {
      //data 是一个数组里面索引分别 time, counter, page_id, pen_status, flyDot, row, col, pressure, long_time, color, online
      // for (let i = 0; i < data.length; i++) {
      let circle;
      if (data[3] == 0) {
        circle = new fabric.Circle({
          top: data[6] * ((this.enlargeHeight / 297) * 2.032), //距离画布上边的距离
          left: data[5] * ((this.enlargeWidth / 210) * 2.032), //距离画布左侧的距离，单位是像素
          radius: 2, //圆形半径
          fill: 'green', //填充的颜色
          strokeWidth: 0, // 边框大小
        });
      } else if (data[3] == 2) {
        circle = new fabric.Circle({
          top: data[6] * ((this.enlargeHeight / 297) * 2.032), //距离画布上边的距离
          left: data[5] * ((this.enlargeWidth / 210) * 2.032), //距离画布左侧的距离，单位是像素
          radius: 2, //圆形半径
          fill: 'red', //填充的颜色
          strokeWidth: 0, // 边框大小
        });
      } else {
        //实时笔画数量  -- 去除起始点 终止点 type==1但x,y==-1   的值才算数
        circle = new fabric.Circle({
          top: data[6] * ((this.enlargeHeight / 297) * 2.032), //距离画布上边的距离
          left: data[5] * ((this.enlargeWidth / 210) * 2.032), //距离画布左侧的距离，单位是像素
          radius: 1, //圆形半径
          fill: 'black', //填充的颜色
          strokeWidth: 0, // 边框大小
        });
      }

      this.ca.add(circle);
      // }
    },
    dots_drawLine(xData, yData) {
      let line;
      for (let i = 1; i < xData.length + 1; i++) {
        //((this.enlargeHeight / 297) * 2.032)  是倍数
        // if (i == 1) {
        //   line = new fabric.Line(
        //     [
        //       xData[i - 1] * ((this.enlargeHeight / 297) * 2.032),
        //       yData[i - 1] * ((this.enlargeWidth / 210) * 2.032),
        //       xData[i] * ((this.enlargeHeight / 297) * 2.032),
        //       yData[i] * ((this.enlargeWidth / 210) * 2.032),
        //     ],
        //     {
        //       stroke: 'green',
        //       strokeWidth: 2,
        //     }
        //   );
        // } else if (i == xData.length - 1) {
        //   line = new fabric.Line(
        //     [
        //       xData[i - 1] * ((this.enlargeHeight / 297) * 2.032),
        //       yData[i - 1] * ((this.enlargeWidth / 210) * 2.032),
        //       xData[i] * ((this.enlargeHeight / 297) * 2.032),
        //       yData[i] * ((this.enlargeWidth / 210) * 2.032),
        //     ],
        //     {
        //       stroke: 'red',
        //       strokeWidth: 2,
        //     }
        //   );
        // } else {
        line = new fabric.Line(
          // [xData[i - 1], yData[i - 1], xData[i], yData[i]],

          [
            xData[i - 1] * ((this.enlargeHeight / 297) * 2.032),
            yData[i - 1] * ((this.enlargeWidth / 210) * 2.032),
            xData[i] * ((this.enlargeHeight / 297) * 2.032),
            yData[i] * ((this.enlargeWidth / 210) * 2.032),
          ],
          {
            stroke: 'black',
            strokeWidth: 1,
          }
        );
        // 创建贝塞尔曲线路径
        // let curve = new fabric.Path('M '+ line1.x2 +' '+ line1.y2 +' Q '+ line2.x1 +' '+ line2.y1 +' '+ line2.x2 +' '+ line2.y2, {
        //   stroke: 'black',
        //   fill: '',
        //   strokeWidth: 2,
        //   selectable: false
        // });
        // }

        this.ca.add(line);
      }
    },

    //发送
    async sendMsg() {
      let _this = this;
      const data = new Uint8Array(_this.toUint8Arr('test1234'));
      if (this.writer) {
        await this.writer.write(data);
      } else {
        Message({
          message: '请先启动串口调试',
          type: 'warning',
        });
      }
    },
    //串口中的配置
    subStrData(str, key1, key2) {
      if (str.indexOf('@[') == -1) {
        // console.log('这笔没有数据');
      } else {
        // console.log('这是每一笔的返回数据:', str);
        let startIndexs = [];
        let endIndexs = [];
        let lastDataArr = [];
        startIndexs = this.findDataIndex(str, key1);
        endIndexs = this.findDataIndex(str, key2);
        // console.log('startIndexs', startIndexs, 'endIndexs', endIndexs);
        //得到前后索引取得中间值
        return (lastDataArr = startIndexs.map((item, index) => {
          let objData = {};
          //startIndexs[index]-1:  { 前一个索引, endIndexs[index]+1: } 后一个索引  因为substring截取不包含起始结束索引
          let strData = JSON.parse(
            str.substring(startIndexs[index] + 1, endIndexs[index] + 1)
          );
          //   console.log('strData', strData);
          //send_data(mac, time, counter, page_id, pen_status, flyDot, row, col, pressure, long_time, color, online);
          //删除掉  x,y == -1 的数据

          objData = {
            counter: strData[1],
            pageId: strData[2],
            dotType: strData[3],
            mac: '',
            x: [strData[5]],
            y: [strData[6]],
          };

          return objData;
        }));
        // lastDataArr = lastDataArr.map((item) => {
        //   console.log('item:', item, 'JSON.parse(item):', JSON.parse(item));
        //   return JSON.parse(item);
        // });
        // console.log('lastDataArr', lastDataArr);
      }
    },
    //将读取的字符串做解析 str:读取的字符串 key截取字符的全部索引
    findDataIndex(str, key) {
      let indexes = [];
      let startIndex = -1;
      while ((startIndex = str.indexOf(key, startIndex + 1)) !== -1) {
        indexes.push(startIndex);
      }
      return indexes;
    },

    //string转uint8array
    toUint8Arr(str) {
      const buffer = [];
      for (let i of str) {
        const _code = i.charCodeAt(0);
        if (_code < 0x80) {
          buffer.push(_code);
        } else if (_code < 0x800) {
          buffer.push(0xc0 + (_code >> 6));
          buffer.push(0x80 + (_code & 0x3f));
        } else if (_code < 0x10000) {
          buffer.push(0xe0 + (_code >> 12));
          buffer.push(0x80 + ((_code >> 6) & 0x3f));
          buffer.push(0x80 + (_code & 0x3f));
        }
      }
      return Uint8Array.from(buffer);
    },
    //uint8array转String
    Uint8ArrayToString(serialData) {
      var out, i, len, c;
      var char2, char3;
      out = '';
      len = serialData.length;
      i = 0;
      while (i < len) {
        c = serialData[i++];
        switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c);
            break;
          case 12:
          case 13:
            // 110x xxxx   10xx xxxx
            char2 = serialData[i++];
            out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
            break;
          case 14:
            // 1110 xxxx  10xx xxxx  10xx xxxx
            char2 = serialData[i++];
            char3 = serialData[i++];
            out += String.fromCharCode(
              ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
            );
            break;
        }
      }
      return out;
    },
    readyXY() {
      // 绘制网格
      let _this = this;
      for (let i = 1; i < _this.enlargeWidth; i++) {
        let line;
        line = new fabric.Line(
          [
            i * ((_this.enlargeWidth / 210) * 2.032),
            0,
            i * ((_this.enlargeWidth / 210) * 2.032),
            _this.enlargeHeight * ((_this.enlargeWidth / 210) * 2.032),
          ],
          {
            stroke: '#ccc',
            strokeWidth: 0.1,
          }
        );

        _this.ca.add(line);
      }
      for (let i = 1; i < _this.enlargeHeight; i++) {
        let line;
        line = new fabric.Line(
          [
            0,
            i * ((_this.enlargeWidth / 210) * 2.032),
            _this.enlargeWidth * ((_this.enlargeWidth / 210) * 2.032),
            i * ((_this.enlargeWidth / 210) * 2.032),
          ],
          {
            stroke: '#ccc',
            strokeWidth: 0.1,
          }
        );
        _this.ca.add(line);
      }
    },
    //取[]
    transform() {
      if (!this.textarea) {
        Message({
          message: '请先填写数据',
          type: 'warning',
        });
        return;
      }
      const str = this.textarea;
      // const regex = /\[(.*?)\]/g;
      const regex = /@\[(.*?)\]/g;
      const matches = str.match(regex);
      // console.log('matches', matches);
      this.testArr = matches.map((item) => {
        // console.log(match[1]);

        let arrString = item.slice(1);
        return JSON.parse(arrString);
      });
      if (this.testArr.length !== 0) {
        Message({
          message: '确认成功，可以点击去送点按键',
          type: 'success',
        });
      }

      console.log(this.testArr);
    },
    //     requestPort----获取授权串口
    // open-----打开串口
    // close—关闭串口(串口关闭前，需要释放锁住的流)
    // cancel—立即退出读取的循环，然后去调用releaseLock，最后调用close方法
    // releaseLock—Reader和.Writer的释放方法
    // read—port.readable.getReader()的读取字节数组方法
    // write—port.writable.getWriter()的写入方法
  },
};
</script>
<style lang="less" scoped>
.infinite-list {
  height: 300px;
}
.el-button {
  width: 124px;
}

.buttons {
  flex: 1;
  display: flex;
  justify-content: space-around;
}

.buttonss {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pc {
  font-size: 24px;
  width: 700px;
  display: flex;
  flex-direction: column;
}
.pcUp,
.pcDown {
  height: 30%;
}
.pcUp {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  .el-button {
    width: 200px;
  }
}
.pcDown {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  .el-button {
    height: 50px;
  }
}
#myCanvas {
  border: 1px solid;
}

.disflal {
  font-size: 14px;
  padding-left: 20px;
}

.disflal > span {
  white-space: nowrap;
  /* margin-right: 30px; */
}

.disflal > div {
  width: 180px;
}
.laber {
  width: 90px;
  display: inline-block;
}

::v-deep.el-dialog {
  border-radius: 16px;
}
.noData {
  margin: 5px 0;
  text-align: center;
}
</style>
